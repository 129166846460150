import React from 'react';

const TaraxaIcon = (): JSX.Element => {
  return (
    <svg
      width='29'
      height='29'
      viewBox='0 0 29 29'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0)'>
        <path
          d='M10.545 27.9845C10.328 28.2029 9.93045 28.2476 9.44923 28.145C8.78494 27.9871 8.29849 27.6189 8.23834 27.1953C8.2247 27.1101 8.23212 27.0228 8.25994 26.9412C8.28776 26.8595 8.33513 26.786 8.39787 26.7271C8.44185 26.6816 8.49204 26.6426 8.54694 26.6113C8.66887 26.5472 8.80218 26.5078 8.93924 26.4956C9.25051 26.47 9.56325 26.5224 9.84943 26.6482C10.1356 26.774 10.3863 26.9692 10.579 27.2164C10.6559 27.3151 10.6995 27.4358 10.7038 27.5611C10.708 27.6864 10.6726 27.8098 10.6026 27.9135C10.5863 27.9394 10.567 27.9633 10.545 27.9845Z'
          fill='#15AC5B'
        />
        <path
          d='M21.1816 27.0692L21.1084 27.1376C20.5513 27.6322 19.6935 27.8952 19.2777 27.7006C19.1839 27.6576 19.1048 27.5878 19.0504 27.4998C18.996 27.4118 18.9686 27.3096 18.9717 27.206C18.995 26.9767 19.1044 26.765 19.2777 26.6141C19.6515 26.2572 20.1289 26.0296 20.6402 25.9644C20.7219 25.9552 20.8044 25.9552 20.8861 25.9644C21.2601 25.9959 21.4091 26.1722 21.451 26.3169C21.5739 26.6483 21.2941 26.964 21.2078 27.0508L21.1816 27.0692Z'
          fill='#15AC5B'
        />
        <path
          d='M8.76659 16.615C8.52138 16.8654 8.21165 17.0421 7.87215 17.1253C7.52147 17.2004 7.15639 17.167 6.82495 17.0294C6.49352 16.8918 6.21125 16.6566 6.01528 16.3545C5.71957 15.923 5.54881 15.4173 5.52203 14.8938C5.49525 14.3703 5.61349 13.8497 5.86359 13.3899C5.96438 13.2063 6.0893 13.0373 6.23497 12.8874C6.4282 12.6907 6.66228 12.5394 6.92045 12.4444C7.17862 12.3493 7.45449 12.3129 7.72831 12.3376C7.97996 12.3736 8.22163 12.461 8.43842 12.5944C8.65522 12.7279 8.84256 12.9046 8.9889 13.1136C9.40749 13.7336 9.57062 14.4928 9.44396 15.2313C9.36631 15.752 9.12959 16.2356 8.76659 16.615Z'
          fill='#15AC5B'
        />
        <path
          d='M14.7894 9.53879C14.7168 9.61435 14.627 9.67119 14.5279 9.70452C14.3889 9.74997 14.2395 9.75191 14.0994 9.71011C13.9593 9.66831 13.8352 9.58472 13.7433 9.4704C13.6228 9.32092 13.5532 9.13652 13.5446 8.94428V8.08144H12.9875H12.9509C12.8012 8.0665 12.6595 8.00619 12.5446 7.90849C12.4297 7.81078 12.347 7.68028 12.3075 7.53427C12.2653 7.37848 12.2631 7.21449 12.3011 7.05761C12.3391 6.90074 12.416 6.7561 12.5246 6.63723L12.5743 6.58988C12.6841 6.49898 12.8194 6.44474 12.9613 6.43468H13.5053V5.6718C13.5004 5.44416 13.5818 5.22316 13.7329 5.05361C13.8408 4.9489 13.9791 4.88162 14.1278 4.86157H14.2062C14.3899 4.8809 14.56 4.96741 14.6844 5.10466C14.8088 5.2419 14.8788 5.42033 14.881 5.60603C14.8967 5.88488 14.9097 6.15057 14.9228 6.41626C15.0972 6.41626 15.2715 6.411 15.4459 6.40048H15.4694C15.6898 6.4218 15.8929 6.53012 16.034 6.7017C16.1752 6.87329 16.243 7.09415 16.2226 7.31593C16.2172 7.51273 16.1389 7.70038 16.003 7.84205C15.8939 7.94995 15.754 8.02079 15.6028 8.04461H15.5793C15.3805 8.04461 15.1922 8.06039 15.0091 8.06302C15.0091 8.32608 15.0327 8.60756 15.0431 8.88114C15.0459 9.12504 14.9551 9.36062 14.7894 9.53879Z'
          fill='#15AC5B'
        />
        <path
          d='M21.6914 18.3253C21.5584 18.4647 21.384 18.557 21.1945 18.5884C21.0451 18.6096 20.8928 18.5835 20.7588 18.5138C20.6247 18.444 20.5156 18.334 20.4465 18.1991C20.3541 18.0138 20.3212 17.8042 20.3524 17.5993C20.4465 16.9548 20.5224 16.305 20.5773 15.6553H20.3419C19.8816 15.6553 19.4082 15.6237 18.9375 15.5974C18.7577 15.585 18.5867 15.5141 18.4504 15.3955C18.3141 15.2769 18.2198 15.1169 18.1816 14.9397C18.1389 14.7623 18.1404 14.577 18.186 14.4003C18.2316 14.2236 18.32 14.061 18.4432 13.927C18.4836 13.8873 18.5273 13.8512 18.5739 13.8191C18.7149 13.7271 18.8792 13.6778 19.0473 13.6771H19.063C19.5416 13.7034 20.0019 13.7244 20.4361 13.7349H20.6793C20.6976 13.0957 20.7002 12.4486 20.6793 11.8093C20.6718 11.5427 20.7653 11.2832 20.9408 11.0833C21.0655 10.9601 21.2275 10.8823 21.4011 10.8623H21.4273C21.8797 10.8833 22.2119 11.2411 22.2537 11.7699C22.2903 12.4223 22.306 13.0852 22.3008 13.7244C22.7585 13.7086 23.2004 13.6849 23.6084 13.6481C23.7057 13.6418 23.8032 13.6574 23.8937 13.6938C23.9842 13.7302 24.0654 13.7865 24.1315 13.8586C24.2224 13.967 24.2906 14.0928 24.332 14.2284C24.3733 14.3641 24.387 14.5067 24.3721 14.6477C24.3498 14.9071 24.2453 15.1524 24.074 15.3475C23.9636 15.4609 23.8194 15.5348 23.6634 15.5579C23.1978 15.6 22.7166 15.6263 22.2223 15.6421C22.1726 16.3208 22.0994 16.9969 22.0026 17.6572C21.9644 17.9052 21.8564 18.137 21.6914 18.3253Z'
          fill='#15AC5B'
        />
        <path
          d='M0.679973 15.4945C0.648201 15.5267 0.609565 15.5513 0.56698 15.5663C0.524394 15.5814 0.478971 15.5865 0.434133 15.5813C0.387968 15.5746 0.343939 15.5574 0.30541 15.5309C0.266882 15.5045 0.234873 15.4695 0.211831 15.4287C0.0963218 15.0991 0.0526176 14.7483 0.0836804 14.4002C0.0995434 13.6462 0.170347 12.8945 0.295521 12.151C0.50766 10.9851 0.858909 9.84924 1.34165 8.76802C1.41681 8.58981 1.52211 8.42604 1.65287 8.28399C1.68356 8.25314 1.71681 8.22498 1.75225 8.19981C1.80928 8.15901 1.87806 8.13819 1.948 8.14056C2.01794 8.14294 2.08517 8.16838 2.13932 8.21297C2.33285 8.37343 2.3067 8.73909 2.05825 9.31519C1.7941 9.92274 1.57129 10.5476 1.39134 11.1856C1.49334 11.3096 1.60722 11.4233 1.73133 11.5249C1.8815 11.6414 1.98462 11.8087 2.02163 11.9958C2.05513 12.2513 2.04093 12.511 1.97979 12.7613C1.94003 12.9567 1.84983 13.1382 1.71825 13.2874C1.65268 13.3592 1.56315 13.4043 1.46669 13.414C1.37023 13.4237 1.2736 13.3974 1.19519 13.34C1.11713 13.2865 1.04285 13.2276 0.972889 13.1638C0.897515 13.6939 0.85211 14.2279 0.836892 14.7632C0.836892 14.7632 0.868276 15.3103 0.679973 15.4945Z'
          fill='#15AC5B'
        />
        <path
          d='M16.2988 23.7678C15.8588 24.2067 15.2956 24.4996 14.6852 24.6069C14.1143 24.7281 13.52 24.6664 12.9857 24.4305C12.4515 24.1946 12.004 23.7964 11.7063 23.2916C11.4634 22.8835 11.3303 22.4188 11.3202 21.9432C11.3101 21.4677 11.4234 20.9976 11.6488 20.5795C11.7834 20.3259 11.9543 20.0937 12.1561 19.8903C12.714 19.3432 13.4541 19.0244 14.2327 18.9959C14.6755 18.9948 15.1133 19.091 15.5153 19.2777C15.9174 19.4645 16.274 19.7373 16.5603 20.077C16.7982 20.3755 16.9698 20.7217 17.0637 21.0924C17.1575 21.4631 17.1714 21.8497 17.1043 22.2262C16.9996 22.8111 16.7183 23.3493 16.2988 23.7678Z'
          fill='#15AC5B'
        />
        <path
          d='M25.9388 22.3999C25.6881 22.6938 25.3463 22.894 24.9685 22.9682C24.8324 22.9815 24.6954 22.9532 24.5755 22.8871C24.4556 22.8209 24.3583 22.7199 24.2964 22.5972C23.8099 21.6844 24.5579 18.8592 25.8656 17.5333C26.009 17.3869 26.1677 17.2563 26.3389 17.144C26.4573 17.0657 26.5897 17.0112 26.7286 16.9835C26.8632 16.9524 27.0037 16.9588 27.135 17.0019C27.2663 17.045 27.3835 17.1232 27.474 17.2282C27.8506 17.6649 27.8427 18.6566 27.4505 19.8114C27.1341 20.7777 26.616 21.6647 25.9309 22.4131L25.9388 22.3999Z'
          fill='#15AC5B'
        />
        <path
          d='M7.17349 5.95293L7.10811 6.01607C5.70106 7.31032 4.29925 7.65757 3.64281 7.33137C3.50027 7.26171 3.38301 7.14889 3.30749 7.00876C3.23197 6.86863 3.20198 6.70823 3.22174 6.55008C3.27405 5.93978 3.72388 5.18216 4.48756 4.41403C5.1754 3.7096 5.98324 3.13493 6.87273 2.71728C7.5501 2.43055 8.07577 2.41476 8.39484 2.67519C8.48596 2.75082 8.55892 2.84617 8.60828 2.95412C8.65764 3.06208 8.68212 3.17985 8.67991 3.29865C8.71391 4.34826 7.18918 5.93715 7.17349 5.95293Z'
          fill='#15AC5B'
        />
        <path
          d='M4.84885 23.8017C4.74989 23.9005 4.62539 23.9693 4.48947 24.0005C4.35355 24.0316 4.21167 24.0238 4.07994 23.978C3.26135 23.7386 2.53168 22.7652 2.17076 22.2049C1.65301 21.4183 1.27349 20.5481 1.04879 19.6322C0.907562 18.9509 0.978176 18.4458 1.25017 18.1696L1.30248 18.1222C1.39127 18.0502 1.49459 17.9986 1.60525 17.9709C1.71591 17.9432 1.83125 17.9401 1.94323 17.9618C2.8089 18.1091 3.9361 19.577 4.24732 20.0952C5.24638 21.7446 5.34053 23.1677 4.91946 23.7228C4.89873 23.7515 4.87506 23.778 4.84885 23.8017Z'
          fill='#15AC5B'
        />
        <path
          d='M28.3292 15.0684C28.4391 14.9579 28.4391 14.8737 28.4077 14.1556V14.1056C28.3689 13.3551 28.2744 12.6086 28.1252 11.8722C28.0154 11.2777 27.8062 10.5964 27.7983 10.57C27.5874 9.871 27.3251 9.18868 27.0137 8.5287C26.9465 8.36626 26.8586 8.21328 26.7522 8.0736C26.7239 8.04149 26.6893 8.01558 26.6506 7.99748C26.6119 7.97937 26.5699 7.96947 26.5273 7.96838C26.4847 7.96722 26.4424 7.97478 26.4028 7.99061C26.3632 8.00644 26.3273 8.0302 26.2971 8.06045C26.2676 8.09064 26.2443 8.1264 26.2286 8.16568C26.2129 8.20496 26.2051 8.24698 26.2056 8.28931C26.2379 8.5638 26.3166 8.83071 26.4384 9.07849C26.7181 9.6886 26.9566 10.317 27.1523 10.9594C27.083 11.1012 26.9951 11.2331 26.8908 11.3513V11.3698V11.3882C26.8573 11.4511 26.8326 11.5184 26.8176 11.5881C26.76 11.8985 26.8751 12.8271 27.2073 13.0507C27.2663 13.0911 27.336 13.1127 27.4073 13.1127C27.4787 13.1127 27.5484 13.0911 27.6074 13.0507L27.6309 13.0323C27.6963 13.4716 27.7434 13.9425 27.78 14.487C27.8062 14.8763 27.8506 15.0631 28.0415 15.1394C28.0914 15.1549 28.1445 15.1565 28.1952 15.144C28.2458 15.1315 28.2922 15.1054 28.3292 15.0684Z'
          fill='#15AC5B'
        />
        <path
          d='M13.9738 28.8396C13.9624 28.8179 13.9536 28.795 13.9477 28.7712H13.4638C13.267 28.7783 13.0747 28.7107 12.9251 28.5818C12.8629 28.5183 12.8281 28.4328 12.8281 28.3437C12.8281 28.2547 12.8629 28.1692 12.9251 28.1057C13.0388 28.0053 13.1873 27.9542 13.3383 27.9636C13.5998 27.9636 13.8614 27.9636 14.1386 27.9636C14.1569 27.8663 14.1726 27.7532 14.1883 27.6295C14.2076 27.5305 14.2548 27.4392 14.3243 27.3665C14.4857 27.2257 14.694 27.1515 14.9075 27.1587C14.9885 27.1533 15.0697 27.1661 15.1452 27.1961C15.2208 27.2262 15.2887 27.2727 15.3442 27.3323C15.3925 27.3913 15.4269 27.4605 15.445 27.5347C15.4631 27.6089 15.4645 27.6863 15.4489 27.7611C15.4358 27.8321 15.4227 27.8873 15.4096 27.9373L15.9745 27.9189C16.0643 27.9156 16.1537 27.9327 16.2361 27.9689C16.283 27.9917 16.3237 28.0258 16.3545 28.0682C16.3853 28.1105 16.4053 28.1598 16.4128 28.2117C16.4203 28.2636 16.415 28.3166 16.3974 28.366C16.3798 28.4154 16.3505 28.4597 16.3119 28.495C16.1221 28.6429 15.8903 28.7259 15.6502 28.7318C15.4122 28.7318 15.1743 28.7475 14.9336 28.7554C14.8428 28.8507 14.7308 28.9228 14.6067 28.9659C14.4757 28.9969 14.3409 29.0084 14.2066 29.0001C14.0131 28.9738 13.9974 28.9106 13.9738 28.8396Z'
          fill='#15AC5B'
        />
        <path
          d='M17.4232 1.73639C17.2872 1.87318 16.874 2.28618 13.1942 2.20726C11.102 2.11519 10.7568 1.79426 10.7071 1.49174C10.6972 1.42427 10.7039 1.35539 10.7267 1.29116C10.7495 1.22693 10.7877 1.16934 10.8378 1.12345C10.9447 1.02555 11.0701 0.950388 11.2066 0.902483C11.782 0.678881 12.9275 0.515784 14.0573 0.5C14.5804 0.5 17.1565 0.5 17.5121 1.2392C17.5442 1.30521 17.5587 1.37848 17.5541 1.45182C17.5495 1.52515 17.526 1.59602 17.486 1.65747C17.4667 1.68503 17.4457 1.71139 17.4232 1.73639Z'
          fill='#15AC5B'
        />
        <path
          d='M25.3658 7.69205C25.1936 7.85452 24.973 7.95531 24.7381 7.97879C23.671 8.13136 21.4428 6.61087 20.5379 4.78786C20.0959 3.89871 20.292 3.41994 20.5379 3.1753L20.5562 3.15688C21.2414 2.51502 22.7374 3.12795 24.2778 4.67737C25.5174 5.92691 25.9437 7.10806 25.3658 7.69205Z'
          fill='#15AC5B'
        />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect
            width='28.5'
            height='28.5'
            fill='white'
            transform='translate(0.083252 0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TaraxaIcon;
